import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, X } from 'lucide-react';

interface MultiSelectDropdownProps {
  options: { id: string; name: string }[];
  selectedValues: string[];
  onChange: (selected: string[]) => void;
  label: string;
  placeholder?: string;
  className?: string;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  selectedValues,
  onChange,
  label,
  placeholder = "Select options",
  className = ""
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleOption = (optionId: string) => {
    const newSelectedValues = selectedValues.includes(optionId)
      ? selectedValues.filter(id => id !== optionId)
      : [...selectedValues, optionId];

    onChange(newSelectedValues);
  };

  const removeSelectedOption = (optionId: string) => {
    onChange(selectedValues.filter(id => id !== optionId));
  };

  const selectedOptionNames = options
    .filter(option => selectedValues.includes(option.id))
    .map(option => option.name);

  return (
    <div
      ref={dropdownRef}
      className={`relative w-full ${className}`}
    >
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>

      {/* Dropdown Trigger */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md cursor-pointer hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <div className="flex flex-wrap gap-1 items-center">
          {selectedValues.length === 0 ? (
            <span className="text-gray-500">{placeholder}</span>
          ) : (
            selectedOptionNames.map(name => (
              <span
                key={name}
                className="inline-flex items-center px-2 py-0.5 rounded-full text-xs bg-blue-100 text-blue-800 mr-1 mb-1"
              >
                {name}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeSelectedOption(
                      options.find(opt => opt.name === name)?.id || ''
                    );
                  }}
                  className="ml-1 hover:text-blue-900"
                >
                  <X size={12} />
                </button>
              </span>
            ))
          )}
        </div>
        <ChevronDown
          size={20}
          className={`text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {options.map(option => (
            <div
              key={option.id}
              onClick={() => toggleOption(option.id)}
              className={`
                px-3 py-2 cursor-pointer
                hover:bg-gray-100
                ${selectedValues.includes(option.id)
                  ? 'bg-blue-50 text-blue-800'
                  : 'text-gray-900'}
              `}
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option.id)}
                  onChange={() => {}} // Prevents React warning
                  className="mr-2 text-blue-600 focus:ring-blue-500 h-4 w-4 border-gray-300 rounded"
                />
                <span>{option.name}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
