import React from 'react';
import { CheckboxProps } from './Input.types';
import { checkboxStyles } from './Input.styles';

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ error, helperText, className = '', label, ...props }, ref) => (
    <div className="flex items-center">
      <input
        type="checkbox"
        ref={ref}
        className={`${checkboxStyles.base} ${error ? checkboxStyles.error : checkboxStyles.default} ${className}`}
        {...props}
      />
      {label && (
        <label htmlFor={props.id} className="ml-2 text-sm text-gray-700">
          {label}
        </label>
      )}
      {helperText && (
        <p className={`mt-1 text-sm ${error ? 'text-red-500' : 'text-gray-500'}`}>
          {helperText}
        </p>
      )}
    </div>
  )
);

Checkbox.displayName = 'Checkbox';
