'use client'

import React, { useState, forwardRef, useRef } from 'react';
import GenericForm from '@/components/atoms/GenericForm';
import { Button } from '@/components/atoms/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atoms/Card';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface ScheduleModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSubmit: (values: ScheduleFormValues) => void;
  initialValues: ScheduleFormValues;
}

interface ScheduleFormValues {
  id: number;
  driver_name: string;
  pick_start_time: string;
  // Add other fields as necessary
}

const formatDateForInput = (dateString: string) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    console.error("Invalid date string:", dateString);
    return '';
  }

  // Format to YYYY-MM-DDTHH:mm for datetime-local input
  return date.toISOString().slice(0, 16);
};

const formatDateForDisplay = (dateString: string) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    console.error("Invalid date string:", dateString);
    return '';
  }

  // Format to dd/mm/yyyy, hh:mm
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year}, ${hours}:${minutes}`;
};

const EditScheduleModal = forwardRef<HTMLDivElement, ScheduleModalProps>(({ open, onOpenChange, onSubmit, initialValues }, ref) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [displayDate, setDisplayDate] = useState(formatDateForDisplay(initialValues.pick_start_time));
  const [pickStartTime, setPickStartTime] = useState<Date | null>(initialValues.pick_start_time ? new Date(initialValues.pick_start_time) : null);

  const handleDateChange = (date: Date | null) => {
    setPickStartTime(date);
  };

  const sections = [
    {
      fields: [
        {
          name: 'driver_name',
          label: 'Assigned Driver',
          type: 'text',
          required: true,
          className: 'w-full'
        },
        {
          name: 'pick_start_time',
          label: 'Pickup Start Time',
          type: 'custom', // Indicate this is a custom field
          required: true,
          className: 'w-full',
          render: () => (
            <DatePicker
              selected={pickStartTime}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="dd/MM/yyyy HH:mm"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholderText="Select date and time"
            />
          ),
        },
      ]
    }
  ];

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]" ref={ref}>
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold">
            Edit Schedule
          </DialogTitle>
        </DialogHeader>
        <GenericForm
          ref={formRef}
          initialValues={initialValues}
          onSubmit={async (values) => {
            await onSubmit(values as ScheduleFormValues);
            onOpenChange(false);
          }}
          method="POST"
          layout="card"
          sections={sections}
        />
        <div className="flex justify-end gap-3 mt-6">
          <Button
            type="button"
            onClick={() => onOpenChange(false)}
            className="px-2 py-2 mx-1 bg-gray-600 text-white rounded-md hover:bg-gray-700 transition duration-300"
          >
            Cancel
          </Button>
          <Button
            type="button"
            className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
            onClick={() => {
              if (formRef.current) {
                formRef.current.submit();
              } else {
                console.error("Form ref is null");
              }
            }}
          >
            Update
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});

EditScheduleModal.displayName = 'EditScheduleModal';

export default EditScheduleModal;
