import React, { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import DashboardPage from '@/pages/dashboard/page';
import Login from '@/pages/auth/Login';
import CollectionSchedule from '@/pages/milk/CollectionSchedule';
import DriverTracking from '@/pages/milk/DriverTracking';
import PickupManagement from '@/pages/milk/PickupManagement';
import QualityTests from '@/pages/quality-control/QualityTests';
import RejectionLogs from '@/pages/quality-control/RejectionLogs';
import FarmersProfiles from '@/pages/farmers/FarmersProfiles';
import PendingPayments from '@/pages/payroll/PendingPayments';
import CompletedPayments from '@/pages/payroll/CompletedPayments';
import DriverManagement from '@/pages/logistics-management/DriverManagement';
import RoutesManagement from '@/pages/logistics-management/RoutesManagement';
import CollectionCenters from '@/pages/logistics-management/CollectionCenters';
import Users from '@/pages/users-management/Users';
import Roles from '@/pages/users-management/Roles';
import Permissions from '@/pages/users-management/Permissions';
import AddNewDriver from '@/pages/driver/AddNewDriver';
import AddNewFarmer from '@/pages/farmers/AddNewFarmer';
import AddNewRoute from '@/pages/route/AddNewRoute';
import AddCollectionCenter from '@/pages/collection-center/AddCollectionCenter'
import AddNewTrip from '@/pages/trips/AddNewTrip'
import AddNewSupply from '@/pages/supply/AddNewSupply'

interface CustomRouteObject {
  path: string;
  element: React.ReactNode;
  public?: boolean;
  roles?: string[];
}

const routes: CustomRouteObject[] = [
  {
    path: '/login',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Login />
      </Suspense>
    ),
    public: true,
  },
  {
    path: '/dashboard',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <DashboardPage />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/milk-collections/collection-schedule',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CollectionSchedule />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/milk-collections/driver-tracking',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <DriverTracking />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/milk-collections/pickup-management',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <PickupManagement />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/quality-control/quality-tests',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <QualityTests />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/quality-control/rejection-logs',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <RejectionLogs />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/farmers/farmers-profiles',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <FarmersProfiles />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/payroll/pending-payments',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <PendingPayments />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/payroll/completed-payments',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <CompletedPayments />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/logistics-management/driver-management',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <DriverManagement />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/logistics-management/routes-management',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <RoutesManagement />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/logistics-management/collection-centers-and-qa-agents',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <CollectionCenters />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/user-management/users',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <Users />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/user-management/roles',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <Roles />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/user-management/permissions',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
         <Permissions />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/driver/add-new-driver',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddNewDriver />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/farmer/add-new-farmer',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddNewFarmer />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/route/add-new-route',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddNewRoute />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/collection-center/add',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddCollectionCenter />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/trip/add',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddNewTrip />
      </Suspense>
    ),
    roles: ['admin', 'user'],
  },
  {
    path: '/supply/add',
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddNewSupply />
      </Suspense>
    ),
    public: true,
  },
];

export { routes };
export type { CustomRouteObject };
