import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import GenericTable from '@/components/atoms/GenericTable';
import reportsService from '@/api/services/reports.service'
import { PendingPaymentReport } from '@/models/report.types';
import { useAuth } from '@/contexts/AuthContext';
import PaymentModal from '@/components/molecules/PaymentModal';

interface PendingPaymentsTableProps {
  orgId: string; // Add orgId as a prop
  populate: string; // Add populate as a prop
  filter: string; // Add filter as a prop
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const PendingPaymentsTable: React.FC<PendingPaymentsTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [payments, setPayments] = useState<PendingPaymentReport[]>([]); // State to hold payments data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();
  const [selectedPayment, setSelectedPayment] = useState<PaymentFormValues | null>(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  useEffect(() => {
    const fetchPaymentsData = async () => {
      try {
        const populate = 'user_role,user_role.user'; // Specify the fields to populate
        const filter = 'status:Pending'; // Specify the filter
        const orgId = user?.org_id
        const fetchedPayments = await reportsService.getPendingPaymentsReports(orgId); // Fetch payments
        setPayments(fetchedPayments);
      } catch (err) {
        setError('Failed to fetch payments');
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentsData();
  }, []); // Dependencies for useEffect

  if (loading) return <div>Loading payments...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Farmer Name', accessor: 'farmer_name', sortable: true },
    { header: 'Member Number', accessor: 'member_number', sortable: true },
    { header: 'Total Amount Due', accessor: 'fatContent', sortable: true }, // missing amount due
    { header: 'Date Due', accessor: 'due_date', sortable: true },
    { header: 'Payment Method', accessor: 'payment_method', sortable: true },
    { header: 'Payment Status', accessor: 'payment_status', sortable: true },
  ];

  const handleActionClick = (actionType: string, item: PaymentFormValues) => {
    console.log(`Action ${actionType} clicked for:`, item);

    switch (actionType) {
      case 'Process':
        // Open the edit modal with the selected item
        console.log('open modal payment')
        setIsPaymentModalOpen(true);
        break;
      default:
        console.warn(`Unknown action type: ${actionType}`);
    }
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'Process', label: 'Process', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-1 mx-1 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={payments}
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />

        {(
          <PaymentModal
            open={isPaymentModalOpen}
            onOpenChange={setIsPaymentModalOpen}
            onSubmit={(values) => {
              console.log('Processing payment:', values);
              // Handle payment processing
              setIsPaymentModalOpen(false);
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};
