export const inputStyles = {
  base: 'w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2',
  default: 'border-gray-300 focus:border-blue-500 focus:ring-blue-200',
  error: 'border-red-500 focus:border-red-500 focus:ring-red-200',
};

export const checkboxStyles = {
  base: 'h-4 w-4 rounded border focus:outline-none focus:ring-2',
  default: 'border-gray-300 text-blue-600 focus:border-blue-500 focus:ring-blue-200',
  error: 'border-red-500 text-red-600 focus:border-red-500 focus:ring-red-200',
};
