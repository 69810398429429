import React, { useEffect, useState } from 'react';
import { Card, CardContent } from '@/components/atoms/Card';
import reportsService from '@/api/services/reports.service'
import GenericTable from '@/components/atoms/GenericTable';
import { formatDate } from '@/utils/date.utils'; // Import the formatDate function
import { useAuth } from '@/contexts/AuthContext';
import EditScheduleModal from '@/components/molecules/EditScheduleModal';

interface ScheduleTableProps {
  orgId: string; // Add orgId as a prop
  populate: string; // Add populate as a prop
  filter: string; // Add filter as a prop
  selectedMonth: string;
  status: string;
  onMonthChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  monthOptions: Array<{ value: string; label: string }>;
  statusOptions: Array<{ value: string; label: string }>;
  className?: string;
  'data-testid'?: string;
}

export const ScheduleTable: React.FC<ScheduleTableProps> = ({
  selectedMonth,
  status,
  onMonthChange,
  onStatusChange,
  monthOptions,
  statusOptions,
  className,
  'data-testid': testId = 'centres-table',
  ...props
}) => {
  const [collections, setCollections] = useState<any[]>([]); // State to hold collections data
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const { user, isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<ScheduleFormValues | null>(null);

   useEffect(() => {
    const fetchCollectionsData = async () => {
      try {
        const populate = ''; // Specify the fields to populate
        const filter = ''; // Specify the filter
        const orgId = user?.org_id
        const fetchedCollections = await reportsService.getCollectionScheduleReports(orgId); // Fetch collections schedule
        setCollections(fetchedCollections);
      } catch (err) {
        setError('Failed to fetch collections');
      } finally {
        setLoading(false);
      }
    };

    fetchCollectionsData();
  }, []); // Dependencies for useEffect

  if (loading) return <div>Loading collections...</div>;
  if (error) return <div>{error}</div>;

  const columns = [
    { header: 'Center Name', accessor: 'route_name', sortable: true},
    { header: 'Driver Name', accessor: 'driver_name', sortable: true },
    { header: 'Pickup Start Name', accessor: 'pick_start_time', sortable: true, render: (item) => formatDate(item.pick_start_time) },
    {
      header: 'Collection Centers',
      accessor: 'collection_centers',
      sortable: true,
      render: (item) => item.collection_centers ? item.collection_centers.length : 'N/A'
    },
    { header: 'Litres Collected', accessor: 'litres_collected', sortable: true },
    { header: 'Pickup Status', accessor: 'pickup_status', sortable: true },
  ];

  const handleActionClick = (actionType: string, item: ScheduleFormValues) => {
    console.log(`Action ${actionType} clicked for:`, item);

    switch (actionType) {
      case 'Edit':
        // Open the edit modal with the selected item
        console.log('selected schedule',selectedSchedule)
        setSelectedSchedule(item);
        setIsModalOpen(true);
        break;
      case 'Delete':
        // Implement delete logic here
        console.log(`Deleting item:`, item);
        // You might want to show a confirmation dialog before deleting
        break;
      case 'View':
        // Implement delete logic here
        console.log(`View item:`, item);
        // You might want to show a confirmation dialog before deleting
        break;
      // Add more cases for other actions as needed
      default:
        console.warn(`Unknown action type: ${actionType}`);
    }
  };

  const renderRowActions = (item: any) => {
    const actions = [
      { type: 'Edit', label: 'Edit', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-1 mx-1 rounded' },
      { type: 'Delete', label: 'Delete', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-1 mx-1 rounded' },
      { type: 'View', label: 'View', className: 'bg-green-800 hover:bg-green-900 text-white font-bold py-1 px-1 mx-1 rounded' },
    ];

    if (actions.length === 0) {
      return null;
    }

    return (
      <>
        {actions.map(action => (
          <button
            key={action.type}
            onClick={() => handleActionClick(action.type, item)}
            className={action.className} // Apply Tailwind CSS classes
          >
            {action.label}
          </button>
        ))}
      </>
    );
  };

  const handleUpdate = (updatedData: ScheduleFormValues) => {
    // Update the schedule data in your state or API
    console.log('Updated Schedule Data:', updatedData);
    // Here you would typically update the state or make an API call
  };

  return (
    <Card className={className} data-testid={testId} {...props}>
      <CardContent className="p-4">
        <GenericTable
          data={collections}
          columns={columns}
          testId={`${testId}-table`}
          renderRowActions={renderRowActions}
        />

        {selectedSchedule && (
          <EditScheduleModal
            open={isModalOpen}
            onOpenChange={setIsModalOpen}
            initialValues={selectedSchedule}
            onSubmit={handleUpdate}
          />
        )}
      </CardContent>
    </Card>
  );
};
