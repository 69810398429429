import React from 'react';
import { Button } from '@/components/atoms/Button';

export interface BalanceHeaderProps {
  balance: number;
  totalDue: number;
  totalOverdue: number;
  onTopUp?: () => void;
  className?: string;
}

export const BalanceHeader: React.FC<BalanceHeaderProps> = ({
  balance,
  totalDue,
  totalOverdue,
  onTopUp,
  className = ''
}) => (
  <div className={`${className}`}>
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <span className="text-gray-700">Balance</span>
        <span className="text-green-700 font-medium">{balance.toLocaleString()}</span>
        {onTopUp && (
          <Button
            variant="secondary"
            size="sm"
            className="bg-orange-600 hover:bg-orange-700 text-white px-3 py-1 text-sm rounded"
            onClick={onTopUp}
          >
            Top Up
          </Button>
        )}
      </div>
      <div className="flex items-center gap-4 text-sm">
        <span className="text-gray-600">Total Due: Ksh <span className="text-red-600">{totalDue.toLocaleString()}</span></span>
        <span className="text-gray-600">Total Overdue: Ksh <span className="text-red-600">{totalOverdue.toLocaleString()}</span></span>
      </div>
    </div>
  </div>
);

export default BalanceHeader;
