'use client'

import React, { useState, useRef } from 'react';
import { Sidebar } from '@/components/organisms/Sidebar';
import GenericForm from '@/components/atoms/GenericForm';
import { Button } from '@/components/atoms/Button';
import userService from '@/api/services/user.service'
import userRoleService from '@/api/services/user-role.service'
import rolesService from '@/api/services/role.service'
import { useAuth } from '@/contexts/AuthContext';
import { UserCreate } from '@/models/user.types';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export default function AddNewDriver() {
  const { user, isAuthenticated } = useAuth();
  const [driverData, setDriverData] = useState({
    fullName: '',
    dateOfBirth: '',
    gender: '',
    photo: null,
    phoneNumber: '',
    emailAddress: '',
    address: '',
    licenseNumber: '',
    licenseExpiry: '',
    licenseDocument: null,
    employeeId: '',
    dateOfHire: '',
    status: '',
    assignedRoute: '',
    additionalInfo: ''
  });

  const formRef = useRef<{ submit: () => void } | null>(null); // Create a ref for the form
  const navigate = useNavigate(); // Get the navigate function

  const handleSubmit = async (values: Record<string, any>, method: string) => {
    // Merge the new values with the existing driverData
    setDriverData(prevData => ({ ...prevData, ...values }));
    const names = values['fullName'].split(' ')
    const member_id = values['employeeId']
    const status = values['status']

    const newUser: UserCreate = {
      username: values['fullName'].replace(/\s+/g, '-'),
      first_name: names[0],
      last_name: names[1],
      email: values['emailAddress'],
      contact_info: {
        phone: values['phoneNumber'],
        address: "123 Main St, Anytown, USA"
      },
      organization_ids: [user?.org_id].filter((id): id is string => id !== undefined),
      is_active: true,
      status: status,
      password: "securepassword" // setup an update for the password form the user
    };
    const populate = ''; // Specify the fields to populate
    const filter = 'name:Driver'; // Specify the filter
    const orgId = user?.org_id;

    const userResp = await userService.create(newUser).then((resp) => { return resp })

    if (userResp){
      const roleResp = await rolesService.fetchRoles(orgId, populate, filter).then((resp) => { return resp });

      const user_role_data = {
        organization_id: user?.org_id || '',
        user_id: userResp.id,
        role_id: roleResp[0].id,
        member_id: member_id,
        status: status,
        location_ids: [],
        route_ids: [],
        available: true,
      };

      await userRoleService.create(user_role_data).then((userRoleResp) => {
        console.log('User role created', userRoleResp);

        navigate('/logistics-management/driver-management');
      }).catch(error => {
        console.log('Error creating user role', error);
      });
    }

  };

  const sections = [
    {
      title: 'Personal Details',
      fields: [
        { name: 'fullName', label: 'Full Name', type: 'text', required: true },
        { name: 'dateOfBirth', label: 'Date of Birth', type: 'date', required: true },
        { name: 'gender', label: 'Gender', type: 'select', options: [
          { value: '', label: 'Select Gender' },
          { value: 'male', label: 'Male' },
          { value: 'female', label: 'Female' },
          { value: 'other', label: 'Other' }
        ], required: true },
        { name: 'photo', label: 'Photo (JPG, PNG)', type: 'file', accept: 'image/jpeg,image/png' },
      ]
    },
    {
      title: 'Contact Information',
      fields: [
        { name: 'phoneNumber', label: 'Phone Number', type: 'text', required: true },
        { name: 'emailAddress', label: 'Email Address', type: 'email', required: true },
        { name: 'address', label: 'Address', type: 'textarea' },
      ]
    },
    {
      title: 'License Details',
      fields: [
        { name: 'licenseNumber', label: 'License Number', type: 'text', required: true },
        { name: 'licenseExpiry', label: 'License Expiry Date', type: 'date', required: true },
        { name: 'licenseDocument', label: 'Upload License Document (PDF, JPG, PNG)', type: 'file', accept: 'image/jpeg,image/png,application/pdf' },
      ]
    },
    {
      title: 'Employment Details',
      fields: [
        { name: 'employeeId', label: 'Employee ID', type: 'text', required: true },
        { name: 'dateOfHire', label: 'Date of Hire', type: 'date', required: true },
        { name: 'status', label: 'Status', type: 'select', options: [
          { value: '', label: 'Select Status' },
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' },
          { value: 'pending', label: 'Pending' }
        ], required: true },
        { name: 'assignedRoute', label: 'Assign Route', type: 'select', options: [
          { value: '', label: 'Select Route' },
          { value: 'route1', label: 'Route 1' },
          { value: 'route2', label: 'Route 2' },
          { value: 'route3', label: 'Route 3' }
        ], required: true },
        { name: 'additionalInfo', label: 'Additional Information', type: 'textarea' },
      ]
    }
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Add New Driver</h1>
            <div className="">
              <GenericForm
                ref={formRef} // Pass the ref to GenericForm
                initialValues={driverData} // Pass the current driverData
                onSubmit={handleSubmit}
                method="POST"
                sections={sections} // Pass all sections at once
                className="p-6"
              />
            </div>
            <div className="mt-8 flex justify-end">
              <Button
                type="button"
                className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.submit(); // Call the submit method on the form ref
                  } else {
                    console.error("Form ref is null");
                  }
                }}
              >
                Save Driver
              </Button>
              <Button
                type="button"
                className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-red-700 transition duration-300"
                onClick={() => console.log(driverData)} // Log current state if needed
              >
                Cancel
              </Button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
