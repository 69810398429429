'use client'

import React, { useState } from 'react'
import { AlertTriangle } from 'lucide-react'
import { Button } from '@/components/atoms/Button'
import { Checkbox } from '@/components/atoms/Input/Checkbox'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atoms/Card'

interface ConfirmBulkPaymentModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onConfirm: (options: { sendNotifications: boolean; attachReceipts: boolean }) => void
  paymentDetails: {
    numberOfPayments: number
    balance: number
    numberOfFarmers: number
    amount: number
  }
}

export const ConfirmBulkPaymentModal: React.FC<ConfirmBulkPaymentModalProps> = ({
  open,
  onOpenChange,
  onConfirm,
  paymentDetails = {
    numberOfPayments: 120,
    balance: 450.0000,
    numberOfFarmers: 120,
    amount: 51200
  }
}) => {
  const [sendNotifications, setSendNotifications] = useState(false)
  const [attachReceipts, setAttachReceipts] = useState(false)

  const handleConfirm = () => {
    onConfirm({
      sendNotifications,
      attachReceipts
    })
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-base font-medium">
            <AlertTriangle className="h-5 w-5 text-orange-500" />
            Confirm Bulk Payment Processing
          </DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <p className="text-sm text-gray-600 mb-4">
            You are about to process {paymentDetails.numberOfPayments} payments.
          </p>
          <div className="space-y-2 mb-4">
            <div className="flex gap-2">
              <span className="text-sm text-gray-600">Balance</span>
              <span className="text-sm font-medium">{paymentDetails.balance.toFixed(4)}</span>
            </div>
            <div className="flex gap-2">
              <span className="text-sm text-gray-600">Number of Farmers:</span>
              <span className="text-sm font-medium">{paymentDetails.numberOfFarmers}</span>
            </div>
            <div className="flex gap-2">
              <span className="text-sm text-gray-600">Amount:</span>
              <span className="text-sm font-medium">KSh {paymentDetails.amount.toLocaleString()}</span>
            </div>
          </div>
          <p className="text-sm text-gray-600 mb-4">
            Are you sure you want to process all pending payments? This action will process payments to all listed recipients.
          </p>
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="notifications"
                checked={sendNotifications}
                onChange={(e) => setSendNotifications(e.target.checked)}
              />
              <label
                htmlFor="notifications"
                className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Send payment notifications to all recipients via email/SMS.
              </label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="receipts"
                checked={attachReceipts}
                onChange={(e) => setAttachReceipts(e.target.checked)}
              />
              <label
                htmlFor="receipts"
                className="text-sm font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Attach payment receipts to notifications.
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-3">
          <Button
            className="bg-green-700 hover:bg-green-800 text-white"
            variant="outline"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button
            className="bg-green-700 hover:bg-green-800 text-white"
            onClick={handleConfirm}
          >
            Yes, Process All Payments
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmBulkPaymentModal
