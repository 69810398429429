import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Input } from './Input';
import { Select } from './Select';
import MultiSelectDropdown from './MultiSelectDropDown'; // Import

interface FieldBase {
  name: string;
  label?: string;
  type: 'text' | 'textarea' | 'url' | 'checkbox' | 'radio' | 'password' | 'date' | 'email' | 'file' | 'select' | 'number' | 'custom' | 'time'  | 'datetime-local';
  required?: boolean;
  placeholder?: string;
  helperText?: string | React.ReactNode;
  className?: string;
  render?: () => React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  ref?: React.Ref<HTMLInputElement>;
}

interface SelectField extends FieldBase {
  type: 'select' | 'radio';
  options: { label: string; value: string }[];
}

interface OtherField extends FieldBase {
  type: Exclude<FieldBase['type'], 'select' | 'radio'>;
}

type Field = SelectField | OtherField;

interface Section {
  title?: string;
  fields: Field[];
  className?: string;
}

interface GenericFormProps {
  initialValues: Record<string, any>;
  onSubmit: (values: Record<string, any>, method: string) => Promise<void>;
  method: 'POST' | 'PUT' | 'DELETE';
  className?: string;
  sections: Section[];
  title?: string;
  subtitle?: string;
  layout?: 'card' | 'sections';
  footer?: React.ReactNode;
}

const GenericForm = forwardRef<HTMLFormElement, GenericFormProps>(({
  initialValues,
  onSubmit,
  method,
  className = '',
  sections,
  title,
  subtitle,
  layout = 'sections',
  footer,
}, ref) => {
  const [formValues, setFormValues] = useState<Record<string, any>>(initialValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Expose the form's submit method to the parent component
  useImperativeHandle(ref, () => ({
    submit: () => {
      const event = new Event('submit', { bubbles: true });
      handleSubmit(event);
    }
  }));

    // Update internal formValues when initialValues change
    console.log('form initail values', initialValues)
  useEffect(() => {
    setFormValues(initialValues);
  }, [initialValues]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : type === 'file' ? (e.target as HTMLInputElement).files?.[0] || null : value,
    }));
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await onSubmit(formValues, method); // Pass formValues to onSubmit
    } catch (err) {
      setError('An error occurred while submitting the form.');
    } finally {
      setLoading(false);
    }
  };

  const renderField = (field: Field) => {
    const fieldProps = {
      ...field,
      value: formValues[field.name] !== undefined ? formValues[field.name] : (field.type === 'checkbox' ? false : ''),
      onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (field.type === 'checkbox') {
          handleChange(field.name, (e.target as HTMLInputElement).checked);
        } else if (field.type === 'file') {
          handleChange(field.name, (e.target as HTMLInputElement).files?.[0] || null)
        }
        else {
          handleChange(field.name, (e.target as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement).value);
        }
      },
    };

    switch (field.type) {
      case 'textarea':
        return (
          <textarea
            name={field.name}
            value={formValues[field.name] || ''}
            onChange={field.onChange || handleChange}
            className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${field.className || ''}`}
            required={field.required}
            placeholder={field.placeholder}
          />
        );
      case 'select':
        return (
          <Select
            name={field.name}
            value={formValues[field.name] || ''}
            onChange={field.onChange || handleChange}
            options={field.options}
            placeholder={field.placeholder}
            ref={field.ref}
            required={field.required}
            className={field.className}
          />
        );
      case 'checkbox':
        return (
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              name={field.name}
              checked={formValues[field.name] || false}
              onChange={field.onChange || handleChange}
              className={`form-checkbox h-4 w-4 text-primary border-gray-300 rounded ${field.className || ''}`}
            />
            <span className="text-sm text-gray-600">{field.label}</span>
          </label>
        );
      case 'custom':
        return field.render ? field.render() : null;
      case 'time':
        return (
          <input
            type="time"
            name={field.name}
            value={formValues[field.name] || ''}
            onChange={field.onChange || handleChange}
            ref={field.ref}
            className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${field.className || ''}`}
            required={field.required}
          />
        );
      case 'datetime-local':
        return (
          <input
            type="datetime-local"
            name={field.name}
            value={formValues[field.name] || ''}
            onChange={field.onChange || handleChange}
            ref={field.ref}
            className={`w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${field.className || ''}`}
            required={field.required}
          />
        );
      default:
        return (
          <Input
            type={field.type}
            name={field.name}
            value={field.type === 'file' ? undefined : formValues[field.name] || ''}
            onChange={field.onChange || handleChange}
            required={field.required}
            placeholder={field.placeholder}
            className={field.className}
          />
        );
    }
  };

  const getGridClassName = (fields: Field[]) => {
    // if (layout !== 'sections') return 'space-y-4';
    if (fields.length === 1) return 'grid grid-cols-1 gap-6';
    if (fields.length % 2 === 0) return 'grid grid-cols-1 md:grid-cols-2 gap-6';
    if (fields.length === 5) return 'grid grid-cols-1 md:grid-cols-2 gap-6';
    return layout === 'sections'? 'grid grid-cols-1 md:grid-cols-3 gap-6' : 'space-y-4';
  };

  const formContent = (
    <>
      {title && (
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
          {subtitle && <p className="mt-2 text-gray-600">{subtitle}</p>}
        </div>
      )}
      {sections.map((section, index) => (
        <div key={index} className={`${layout === 'sections' ? 'bg-white shadow-md rounded-lg p-6 mb-4' : 'space-y-4'} ${section.className || ''}`}>
          {section.title && layout === 'sections' && (
            <h2 className="text-lg font-medium mb-4">{section.title}</h2>
          )}
          {/* <div className={getGridClassName(section.fields)}> */}
          <div className={layout === 'sections' ? 'grid grid-cols-1 md:grid-cols-2 gap-6' : 'space-y-4'}>
            {section.fields.map((field) => (
              <div key={field.name} className={`${section.fields.length === 1 || field.type === 'textarea' ? 'md:col-span-2' : ''}`}>
                {field.label && field.type !== 'checkbox' && (
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    {field.label}
                    {field.required && <span className="text-red-500">*</span>}
                  </label>
                )}
                {renderField(field)}
                {field.helperText && (
                  <p className="mt-1 text-sm text-gray-500">{field.helperText}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
      {error && <div className="mt-2 text-red-600">{error}</div>}
      {footer}
    </>
  );

  return (
    <form
      ref={ref} // Attach the ref to the form element
      onSubmit={handleSubmit}
      className={`${layout === 'card' ? 'bg-white rounded-lg shadow-sm p-8 max-w-md mx-auto' : ''} ${className}`}
    >
      {formContent}
    </form>
  );
});

export default GenericForm;
