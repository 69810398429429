'use client'
import { useState } from 'react';
import { Sidebar } from "@/components/organisms/Sidebar";
import { DashboardHeader } from "@/components/molecules/DashboardHeader";
import { KPICard } from "@/components/molecules/KPICard";
import { CollectionChart } from "@/components/molecules/CollectionChart";
import { CentresTable } from "@/components/molecules/CentresTable";
import { MONTH_OPTIONS, STATUS_OPTIONS, KPI_DATA, CENTRES_DATA } from './constants';
import { Select } from '@/components/atoms/Select';
import { CollectionProvider } from '@/contexts/CollectionContext';

export function DashboardContent() {
  const [status, setStatus] = useState('all');
  const [selectedMonth, setSelectedMonth] = useState('october');

  return (
    <CollectionProvider>
      <div className="flex h-screen overflow-hidden bg-gray-100">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <DashboardHeader />
          <main className="flex-1 overflow-y-auto">
            <div className="p-6">
              {/* Dashboard Header */}
              <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
                <div className="flex gap-4">
                  <Select
                    options={STATUS_OPTIONS}
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="w-[180px]"
                    placeholder="Select status"
                  />
                  <Select
                    options={MONTH_OPTIONS}
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="w-[180px]"
                    placeholder="Select month"
                  />
                </div>
              </div>

              {/* KPI Cards */}
              <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
                {KPI_DATA.map((kpi, index) => (
                  <KPICard key={index} {...kpi} />
                ))}
              </div>

              {/* Collection Chart */}
              <CollectionChart
                selectedMonth={selectedMonth}
                status={status}
                onMonthChange={setSelectedMonth}
                onStatusChange={setStatus}
                monthOptions={MONTH_OPTIONS}
                statusOptions={STATUS_OPTIONS}
              />

              {/* Centres Table */}
              <CentresTable
                selectedMonth={selectedMonth}
                status={status}
                onMonthChange={setSelectedMonth}
                onStatusChange={setStatus}
                monthOptions={MONTH_OPTIONS}
                statusOptions={STATUS_OPTIONS}
              />
            </div>
          </main>
        </div>
      </div>
    </CollectionProvider>
  );
}
