// src/api/services/supply.service.ts
import { Supply, SupplyCreate, SupplyUpdate } from '../../models/supply.types';
import { BaseService } from './base.service';
import { API_ENDPOINTS } from '../endpoints';

class SupplyService extends BaseService<Supply, SupplyCreate, SupplyUpdate> {
  constructor() {
    super(API_ENDPOINTS.ROLES.BASE);
  }
}

export default new SupplyService();