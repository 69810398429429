'use client'

import React from 'react';
import { useState, useEffect } from 'react';
import GenericForm from '@/components/atoms/GenericForm';
import { Button } from '@/components/atoms/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';
import logoImage from '@/assets/images/logo.png';

export default function Login() {
  const initialValues = {
    email: '',
    password: '',
    rememberMe: false
  };

  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState<string>('');

  const from = location.state?.from?.pathname || '/dashboard';

  const sections = [
    {
      fields: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email address',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent'
        },
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent'
        },
        {
          name: 'rememberMe',
          type: 'checkbox',
          label: 'Remember Password',
          className: 'text-green-600 border-gray-300 rounded focus:ring-green-500'
        }
      ]
    }
  ];

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from]);

  const handleSubmit = async (values: { email: string; password: string }, method: string) => {
    setError('');
    try {
      await login(values.email, values.password);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Login failed. Please try again.');
    }
  };


  return (
    <div className="min-h-screen bg-[#2B4332] flex items-center justify-center p-4">
      <GenericForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        method="POST"
        layout="card"
        className="w-full max-w-md"
        title={
          <div className="flex flex-col items-center mb-12">
            <div className="relative h-16 w-48 mb-8">
              <img
                src={logoImage}
                alt="ZOA"
                className="w-full h-full object-contain"
              />
            </div>
            <h1 className="text-2xl font-bold text-gray-800">Login to Account</h1>
          </div>
        }
        sections={sections}
        footer={
          <div className="space-y-4 mt-6">
            <Button
              type="submit"
              className="w-full bg-green-800 hover:bg-green-900 text-white py-3 rounded-md transition duration-300 ease-in-out"
            >
              Sign In
            </Button>
            <div className="flex items-center justify-between text-sm">
              <button href="/forgot-password" className="text-gray-600 hover:text-gray-800 transition duration-300 ease-in-out">
                Forget Password?
              </button>
              <div className="text-gray-600">
                Don't have an account?{' '}
                <button href="/signup" className="text-green-600 hover:text-green-700 transition duration-300 ease-in-out">
                  Create Account
                </button>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
