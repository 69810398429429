import React from 'react';
import { DialogProps, DialogContentProps, DialogHeaderProps, DialogTitleProps } from './Card.types';
import { dialogStyles } from './Card.styles';

export const Dialog: React.FC<DialogProps> = ({ children, open, onOpenChange, ...props }) => {
  if (!open) return null;

  return (
    <div className={dialogStyles.overlay} onClick={() => onOpenChange(false)}>
      <div className={dialogStyles.container} onClick={(e) => e.stopPropagation()} {...props}>
        {children}
      </div>
    </div>
  );
};

export const DialogContent: React.FC<DialogContentProps> = ({ children, className = '', ...props }) => (
  <div className={`${dialogStyles.content} ${className}`} {...props}>
    {children}
  </div>
);

export const DialogHeader: React.FC<DialogHeaderProps> = ({ children, className = '', ...props }) => (
  <div className={`${dialogStyles.header} ${className}`} {...props}>
    {children}
  </div>
);

export const DialogTitle: React.FC<DialogTitleProps> = ({ children, className = '', ...props }) => (
  <h2 className={`${dialogStyles.title} ${className}`} {...props}>
    {children}
  </h2>
);
