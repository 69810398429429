// src/pages/supplies/AddNewSupply.tsx
'use client'

import React, { useState, useRef } from 'react';
import { Sidebar } from '@/components/organisms/Sidebar';
import GenericForm from '@/components/atoms/GenericForm';
import { Button } from '@/components/atoms/Button';
import { useAuth } from '@/contexts/AuthContext';
import supplyService from '@/api/services/supply.service'; // Assuming you have a supply service for API calls
import { useNavigate } from 'react-router-dom';

interface SupplyItem {
  item: string;
  quantity: number;
}

interface SupplyData {
  producer_id: string;
  organization_id: string;
  supply_items: SupplyItem[];
  total_cost: number;
  timestamp: string;
  status: string;
  supplier_details: {
    additionalProp1: string;
  };
  supply_category: string;
}

export default function AddNewSupply() {
  const { user } = useAuth();
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const [supplyData, setSupplyData] = useState<SupplyData>({
    producer_id: '',
    organization_id: user?.org_id || '',
    supply_items: [{ item: '', quantity: 0 }],
    total_cost: 0,
    timestamp: new Date().toISOString(),
    status: 'ordered',
    supplier_details: {
      additionalProp1: ''
    },
    supply_category: 'seeds'
  });

  const handleSubmit = async (values: Record<string, any>, method: string) => {
    console.log('Form submitted with values:', values);
    try {
      const supplyPayload = {
        producer_id: values.producer_id,
        organization_id: values.organization_id,
        supply_items: values.supply_items,
        total_cost: values.total_cost,
        timestamp: values.timestamp,
        status: values.status,
        supplier_details: values.supplier_details,
        supply_category: values.supply_category
      };

      console.log('Sending supply data:', supplyPayload);
      const response = await supplyService.create(supplyPayload); // Assuming you have a create method in supplyService
      console.log('Supply created:', response);

      navigate('/logistics-management/supplies'); // Redirect after successful creation
    } catch (error) {
      console.error('Error creating supply:', error);
      // Handle error (show error notification, etc.)
    }
  };

  const sections = [
    {
      fields: [
        {
          name: 'producer_id',
          label: 'Producer ID',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'supply_items',
          label: 'Supply Items',
          type: 'custom',
          required: true,
          className: 'w-full',
          render: () => (
            <div>
              {supplyData.supply_items.map((item, index) => (
                <div key={index} className="flex space-x-2 mb-2">
                  <input
                    type="text"
                    placeholder="Item"
                    value={item.item}
                    onChange={(e) => {
                      const newItems = [...supplyData.supply_items];
                      newItems[index].item = e.target.value;
                      setSupplyData({ ...supplyData, supply_items: newItems });
                    }}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  />
                  <input
                    type="number"
                    placeholder="Quantity"
                    value={item.quantity}
                    onChange={(e) => {
                      const newItems = [...supplyData.supply_items];
                      newItems[index].quantity = Number(e.target.value);
                      setSupplyData({ ...supplyData, supply_items: newItems });
                    }}
                    className="w-24 px-3 py-2 border border-gray-300 rounded-md"
                  />
                </div>
              ))}
              <Button
                type="button"
                onClick={() => {
                  setSupplyData(prev => ({
                    ...prev,
                    supply_items: [...prev.supply_items, { item: '', quantity: 0 }]
                  }));
                }}
                className="mt-2 bg-blue-500 text-white rounded-md px-4 py-2"
              >
                Add Item
              </Button>
            </div>
          )
        },
        {
          name: 'total_cost',
          label: 'Total Cost',
          type: 'number',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'timestamp',
          label: 'Timestamp',
          type: 'datetime-local',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'status',
          label: 'Status',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'supplier_details.additionalProp1',
          label: 'Supplier Additional Property',
          type: 'text',
          required: false,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'supply_category',
          label: 'Supply Category',
          type: 'text',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        }
      ]
    }
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-6 py-8">
            <div className="bg-white shadow-md rounded-lg mb-6 p-6">
              <h1 className="text-2xl font-semibold text-gray-800">Add Supply</h1>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <GenericForm
                ref={formRef}
                initialValues={supplyData}
                onSubmit={handleSubmit}
                method="POST"
                sections={sections}
                className="p-6"
              />
              <div className="flex justify-between px-6 py-4 bg-gray-50 border-t border-gray-200">
                <div className="space-x-4">
                  <Button
                    type="button"
                    className="px-6 py-2 bg-green-700 text-white rounded-md hover:bg-green-800"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.submit();
                      } else {
                        console.error("Form ref is null");
                      }
                    }}
                  >
                    Save Supply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
