import React, { useState } from 'react';
import { Button } from '@/components/atoms/Button';
import { MONTH_OPTIONS, STATUS_OPTIONS, KPI_DATA, CENTRES_DATA } from '@/pages/dashboard/constants';
import { PendingPaymentsTable } from "@/components/molecules/PendingPaymentsTable";
import { CollectionProvider } from '@/contexts/CollectionContext';
import { FilterControls } from '@/components/molecules/FilterControls';
import { Sidebar } from '@/components/organisms/Sidebar';
import PaymentModal from '@/components/molecules/PaymentModal';
import ConfirmBulkPaymentModal from '@/components/molecules/ConfirmBulkPaymentModal';

export default function PendingPayments() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [status, setStatus] = useState('');
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isBulkPaymentModalOpen, setIsBulkPaymentModalOpen] = useState(false);

  const monthOptions = [
    { value: '1', label: 'January' },
    { value: '2', label: 'February' },
    // ... add all months
  ];

  const statusOptions = [
    { value: 'all', label: 'All Status' },
    { value: 'completed', label: 'Completed' },
    { value: 'in_progress', label: 'In Progress' },
    { value: 'delayed', label: 'Delayed' },
  ];

  const balanceHeaderOptions= {
    balance: 450000,
    totalDue: 51200,
    totalOverdue: 78000,
    onTopUp: () => console.log('Top up clicked'),
  }

  const handleProcessPayments = async (values: Record<string, any>) => {
    console.log('Processing payment with values:', values);
    // Add your payment processing logic here
  };

  const handleOpenSinglePaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const handleOpenBulkPaymentModal = () => {
    setIsBulkPaymentModalOpen(true);
  };

  const handleConfirmBulkPayment = (options: { sendNotifications: boolean; attachReceipts: boolean }) => {
    console.log('Processing bulk payments with options:', options);
    // Add your bulk payment processing logic here
    setIsBulkPaymentModalOpen(false);
  };

  return (
    <CollectionProvider>
      <div className="flex h-screen bg-gray-100">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <header className="bg-white shadow">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
              <h1 className="text-3xl font-bold text-gray-900">Pending Payments</h1>
              <div className="mt-4 flex justify-between items-center">
                <div className="space-x-2">
                  <Button
                    className="px-2 py-2 mx-1 bg-green-800 text-white rounded-md hover:bg-green-900 transition duration-300"
                    onClick={handleOpenBulkPaymentModal}
                  >
                    Process Payments
                  </Button>
                  <Button className="px-2 py-2 mx-1 bg-green-800 text-white rounded-md hover:bg-green-900 transition duration-300">Export Data</Button>
                </div>
              </div>
            </div>
          </header>
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
            <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
              <div className="px-4 py-6 sm:px-0">
                <div className='pb-4'>
                  <FilterControls
                    monthOptions={monthOptions}
                    statusOptions={statusOptions}
                    selectedMonth={selectedMonth}
                    status={status}
                    onMonthChange={setSelectedMonth}
                    onStatusChange={setStatus}
                    searchTerm={searchTerm}
                    onSearchChange={(e) => setSearchTerm(e.target.value)}
                    selectedDate={selectedDate}
                    onDateChange={(e) => setSelectedDate(e.target.value)}
                    balanceHeader={balanceHeaderOptions}
                  />
                </div>
                <PendingPaymentsTable
                  data={CENTRES_DATA}
                  selectedMonth={selectedMonth}
                  status={status}
                  onMonthChange={setSelectedMonth}
                  onStatusChange={setStatus}
                  monthOptions={MONTH_OPTIONS}
                  statusOptions={STATUS_OPTIONS}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
      <PaymentModal
        open={isPaymentModalOpen}
        onOpenChange={setIsPaymentModalOpen}
        onSubmit={(values) => {
          console.log('Processing payment:', values);
          // Handle payment processing
          setIsPaymentModalOpen(false);
        }}
      />
      <ConfirmBulkPaymentModal
        open={isBulkPaymentModalOpen}
        onOpenChange={setIsBulkPaymentModalOpen}
        onConfirm={handleConfirmBulkPayment}
        paymentDetails={{
          numberOfPayments: 120,
          balance: 450.0000,
          numberOfFarmers: 120,
          amount: 51200
        }}
      />
    </CollectionProvider>
  );
}
