// src/pages/trips/AddNewTrip.tsx
'use client'

import React, { useState, useRef, useEffect } from 'react';
import { Sidebar } from '@/components/organisms/Sidebar';
import GenericForm from '@/components/atoms/GenericForm';
import { Button } from '@/components/atoms/Button';
import { useAuth } from '@/contexts/AuthContext';
import tripService from '@/api/services/trip.service'; // Assuming you have a trip service for API calls
import roleService from '@/api/services/role.service'; // Assuming you have a role service for fetching roles
import routeService from '@/api/services/route.service'; // Import route service
import MultiSelectDropdown from '@/components/atoms/MultiSelectDropDown'; // Import the new MultiSelectDropdown
import ErrorBoundary from '@/components/atoms/ErrorBoundary';
import { useNavigate } from 'react-router-dom';

interface TripData {
  user_roles_ids: string[];
  organization_id: string;
  route_id: string;
  start_time: string;
  end_time: string;
  status: string;
}

interface UserRole {
  id: string;
  name: string;
}

interface Route {
  id: string;
  name: string;
}

export default function AddNewTrip() {
  const { user } = useAuth();
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const [tripData, setTripData] = useState<TripData>({
    user_roles_ids: [], // Ensure this matches the type
    organization_id: user?.org_id || '',
    route_id: '',
    start_time: '',
    end_time: '',
    status: ''
  });

  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [routes, setRoutes] = useState<Route[]>([]); // State for routes

  useEffect(() => {
    const fetchUserRoles = async () => {
      try {
        const roles = await roleService.fetchRoles(user?.org_id, '', ''); // Fetch roles based on organization ID
        const formattedRoles = roles.map(role => ({
          id: role.id,
          name: role.name
        }));
        setUserRoles(formattedRoles);
      } catch (error) {
        console.error('Error fetching user roles:', error);
      }
    };

    const fetchRoutes = async () => {
      try {
        const fetchedRoutes = await routeService.fetchRoutes(user?.org_id, '', ''); // Fetch routes based on organization ID
        const formattedRoutes = fetchedRoutes.map(route => ({
          id: route.id,
          name: route.name
        }));
        setRoutes(formattedRoutes);
      } catch (error) {
        console.error('Error fetching routes:', error);
      }
    };

    if (user?.org_id) {
      fetchUserRoles();
      fetchRoutes();
    }
  }, [user?.org_id]);

  const handleSubmit = async (values: Record<string, any>, method: string) => {
    try {
      // The values object now contains all form data, including user_roles_ids
      const tripPayload = {
        ...values, // Include all form values
        organization_id: user?.org_id || '', // Ensure organization_id is set
      };

      const response = await tripService.create(tripPayload); // Assuming you have a create method in tripService
      console.log('Trip created:', response);

      navigate('/logistics-management/trips'); // Redirect after successful creation
    } catch (error) {
      console.error('Error creating trip:', error);
      // Handle error (show error notification, etc.)
    }
  };

  const sections = [
    {
      fields: [
        {
          name: 'user_roles_ids',
          label: 'User Roles',
          type: 'custom',
          required: true,
          initialValue: [],
          className: 'w-full',
          render: () => (
            <MultiSelectDropdown
              options={userRoles}
              selectedValues={tripData.user_roles_ids}
              onChange={(selected) => {
                setTripData(prev => ({ ...prev, user_roles_ids: selected }));
              }}
              label=""
            />
          )
        },
        {
          name: 'route_id',
          label: 'Route',
          type: 'select',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md',
          options: [
            { value: '', label: 'Select Route' }, // Default label
            ...routes.map(route => ({
              value: route.id,
              label: route.name
            }))
          ]
        },
        {
          name: 'start_time',
          label: 'Start Time',
          type: 'datetime-local',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'end_time',
          label: 'End Time',
          type: 'datetime-local',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md'
        },
        {
          name: 'status',
          label: 'Status',
          type: 'select',
          required: true,
          className: 'w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md',
          options: [
            { value: '', label: 'Select Status' },
            { value: 'scheduled', label: 'Scheduled' },
            { value: 'in_progress', label: 'In Progress' },
            { value: 'completed', label: 'Completed' }
          ]
        }
      ]
    }
  ];

  return (
      <div className="flex h-screen bg-gray-100">
        <Sidebar />
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
            <div className="container mx-auto px-6 py-8">
              <div className="bg-white shadow-md rounded-lg mb-6 p-6">
                <h1 className="text-2xl font-semibold text-gray-800">Add Trip</h1>
              </div>
              <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <GenericForm
                  ref={formRef}
                  initialValues={tripData}
                  onSubmit={handleSubmit} // onSubmit now receives form values
                  method="POST"
                  sections={sections}
                  className="p-6"
                />
                <div className="flex justify-between px-6 py-4 bg-gray-50 border-t border-gray-200">
                  <div className="space-x-4">
                    <Button
                      type="button"
                      className="px-6 py-2 bg-green-700 text-white rounded-md hover:bg-green-800"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
                      onClick={() => {
                        if (formRef.current) {
                          formRef.current.submit();
                        } else {
                          console.error("Form ref is null");
                        }
                      }}
                    >
                      Save Trip
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
  );
}
