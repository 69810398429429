import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronDown, LogOut, Settings, LayoutDashboard, Milk, FlaskConical, Users, Wallet, Wheat, Truck, UserCog, PhoneCall, BarChart3 } from 'lucide-react';
import { Button } from '@/components/atoms/Button';
import cn from 'classnames';
import logoImage from '@/assets/images/logo.png';

interface SidebarProps {
  className?: string;
  'data-testid'?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({
  className,
  'data-testid': testId = 'sidebar',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeItem, setActiveItem] = useState('');
  const [activeSubItem, setActiveSubItem] = useState('');
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const location = useLocation();

  const sidebarItems = [
    { name: 'Dashboard', icon: <LayoutDashboard size={20} />, path: '/dashboard' },
    { name: 'Milk Collection', icon: <Milk size={20} />, subItems: [
        { name: 'Collection Schedule', path: '/milk-collections/collection-schedule' },
        { name: 'Driver Tracking', path: '/milk-collections/driver-tracking' },
        { name: 'Pickup Management', path: '/milk-collections/pickup-management' },
        { name: 'Inventory', path: '/milk-collections/inventory' }
      ]
    },
    { name: 'Quality Control', icon: <FlaskConical size={20} />, subItems: [
        { name: 'Quality Tests (On-Site)', path: '/quality-control/quality-tests' },
        { name: 'Rejection Logs', path: '/quality-control/rejection-logs' },
      ]
    },
    { name: 'Farmers', icon: <Users size={20} />, subItems: [
        { name: 'Farmer Profiles', path: '/farmers/farmers-profiles' }
      ]
    },
    { name: 'Payroll', icon: <Wallet size={20} />, subItems: [
        { name: 'Pending Payments', path: '/payroll/pending-payments' },
        { name: 'Completed Payments', path: '/payroll/completed-payments' }
      ]
    },
    { name: 'Feed Management', icon: <Wheat size={20} />, path: '/feed-management' },
    { name: 'Logistics Management', icon: <Truck size={20} />, subItems: [
        { name: 'Driver Management', path: '/logistics-management/driver-management' },
        { name: 'Routes Management', path: '/logistics-management/routes-management' },
        { name: 'Collection Centers & QA Agents', path: '/logistics-management/collection-centers-and-qa-agents' },
      ]
    },
    { name: 'User Management', icon: <UserCog size={20} />, subItems: [
        { name: 'Users', path: '/user-management/users' },
        { name: 'Roles', path: '/user-management/roles' },
        { name: 'Permissions', path: '/user-management/permissions' },
      ]
    },
    { name: 'Extension Services', icon: <PhoneCall size={20} />, subItems: [
        { name: 'Assignments', path: '/extension-services/assignments' },
        { name: 'Call Logs', path: '/extension-services/call-logs' }
      ]
    },
    { name: 'Reports', icon: <BarChart3 size={20} />, subItems: [
        { name: 'Quality', path: '/reports/quality' },
        { name: 'Milk Volume', path: '/reports/milk-volume' },
        { name: 'Extension Services', path: '/reports/extension-services' }
      ]
    },
  ] as const;

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
      setIsOpen(window.innerWidth >= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    let newActiveItem = '';
    let newActiveSubItem = '';
    let newExpandedItems: string[] = [];

    sidebarItems.forEach(item => {
      if (item.path === currentPath) {
        newActiveItem = item.name;
        newExpandedItems.push(item.name);
      } else if (item.subItems) {
        const activeSubItem = item.subItems.find(subItem => subItem.path === currentPath);
        if (activeSubItem) {
          newActiveItem = item.name;
          newActiveSubItem = activeSubItem.name;
          newExpandedItems.push(item.name);
        }
      }
    });

    setActiveItem(newActiveItem);
    setActiveSubItem(newActiveSubItem);
    setExpandedItems(prevExpandedItems => {
      const combinedItems = [...new Set([...prevExpandedItems, ...newExpandedItems])];
      return combinedItems;
    });
  }, [location]);

  const handleItemClick = (itemName: string) => {
    setExpandedItems(prev => {
      if (prev.includes(itemName)) {
        return prev.filter(item => item !== itemName);
      } else {
        return [...prev, itemName];
      }
    });
  };

  const handleSubItemClick = (itemName: string, subItemName: string) => {
    setActiveItem(itemName);
    setActiveSubItem(subItemName);
    setExpandedItems(prev => {
      if (!prev.includes(itemName)) {
        return [...prev, itemName];
      }
      return prev;
    });
  };

  return (
    <aside
      className={cn(
        "w-64 bg-white shadow-md h-screen overflow-y-auto",
        "transition-all duration-300",
        "fixed md:relative",
        {
          "translate-x-0": isOpen,
          "-translate-x-full": !isOpen,
          "md:translate-x-0": !isMobile
        },
        className
      )}
      data-testid={testId}
      {...props}
    >
      <div className="p-4" data-testid={`${testId}-header`}>
        <img
          src={logoImage}
          alt="ZOA Logo"
          className="w-40 h-30 object-contain"
        />
      </div>

      <nav className="mt-2" data-testid={`${testId}-nav`}>
        {sidebarItems.map((item) => (
          <div key={item.name} data-testid={`${testId}-item-${item.name.toLowerCase()}`}>
            <Link
              to={item.path || '#'}
              className={cn(
                "flex items-center w-full px-6 py-2 text-left text-sm",
                "transition-colors duration-200",
                {
                  'bg-green-50 text-green-700': activeItem === item.name,
                  'text-gray-700 hover:bg-gray-50': activeItem !== item.name
                }
              )}
              onClick={() => handleItemClick(item.name)}
              data-testid={`${testId}-button-${item.name.toLowerCase()}`}
            >
              <span className="mr-3 text-current" role="img" aria-hidden="true">
                {item.icon}
              </span>
              {item.name}
              {item.subItems && item.subItems.length > 0 && (
                <ChevronDown
                  className={cn(
                    "ml-auto transition-transform duration-200",
                    { 'transform rotate-180': expandedItems.includes(item.name) }
                  )}
                  size={16}
                />
              )}
            </Link>
            {item.subItems && item.subItems.length > 0 && expandedItems.includes(item.name) && (
              <div
                className="ml-11 mt-1 border-l border-gray-200"
                data-testid={`${testId}-subitems-${item.name.toLowerCase()}`}
              >
                {item.subItems.map((subItem) => (
                  <Link
                    key={subItem.name}
                    to={subItem.path}
                    className={cn(
                      "block w-full px-4 py-2 text-sm text-left",
                      "transition-colors duration-200",
                      {
                        'text-green-700 bg-green-50': activeSubItem === subItem.name,
                        'text-gray-600 hover:text-green-700 hover:bg-green-50': activeSubItem !== subItem.name
                      }
                    )}
                    onClick={() => handleSubItemClick(item.name, subItem.name)}
                    data-testid={`${testId}-subitem-${subItem.name.toLowerCase().replace(/\s+/g, '-')}`}
                  >
                    {subItem.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>

      <div className="absolute bottom-0 w-full p-4 border-t border-gray-100" data-testid={`${testId}-footer`}>
        <Button
          variant="ghost"
          className="w-full flex px-6 py-2 text-sm text-gray-700 hover:bg-gray-50"
          data-testid={`${testId}-settings-button`}
        >
          <Settings className="mr-3" size={20} />
          Settings
        </Button>
        <Button
          variant="ghost"
          className="w-full mt-1 flex items-center px-6 py-2 text-sm text-gray-700 hover:bg-gray-50"
          data-testid={`${testId}-logout-button`}
        >
          <LogOut className="mr-3" size={20} />
          Logout
        </Button>
      </div>
    </aside>
  );
};

export default Sidebar;
