// src/api/services/trip.service.ts
import { Trip, TripCreate, TripUpdate } from '../../models/trip.types';
import { BaseService } from './base.service';
import { API_ENDPOINTS } from '../endpoints';
import api from '../axios';

class TripService extends BaseService<Trip, TripCreate, TripUpdate> {
  constructor() {
    super(API_ENDPOINTS.TRIPS.BASE);
  }

  // Updated method to fetch locations with dynamic populate and filter parameters
  async fetchTrips(orgId: string, populate: string, filter: string) {
    const response = await api.get<Trip[]>(`${this.endpoint}?populate=${populate}&org_id=${orgId}&filter=${filter}`);
    return response.data;
  }
}

export default new TripService();
