'use client'

import React, { useState, forwardRef, useRef } from 'react';
import GenericForm from '@/components/atoms/GenericForm';
import { Input } from '@/components/atoms/Input'
import { Button } from '@/components/atoms/Button'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atoms/Card'

interface PaymentModalProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onSubmit: (values: PaymentFormValues) => void
}

interface PaymentFormValues {
  recipientName: string
  recipientId: string
  paymentAmount: string
  paymentMethod: string
  notes?: string
}

const PaymentModal = forwardRef<HTMLDivElement, PaymentModalProps>(({ open, onOpenChange, onSubmit }, ref) => {
  const [initialValues] = useState({
    recipientName: '',
    recipientId: '',
    paymentAmount: '',
    paymentMethod: '',
    notes: ''
  });

  const formRef = useRef<HTMLFormElement>(null);

  const sections = [
    {
      fields: [
        {
          name: 'recipientName',
          label: 'Recipient Name',
          type: 'text',
          required: true,
          className: 'w-full'
        },
        {
          name: 'recipientId',
          label: 'Recipient ID',
          type: 'text',
          required: true,
          className: 'w-full'
        },
        {
          name: 'paymentAmount',
          label: 'Payment Amount',
          type: 'number',
          required: true,
          className: 'w-full'
        },
        {
          name: 'paymentMethod',
          label: 'Payment Method',
          type: 'select',
          required: true,
          options: [
            { value: 'bank_transfer', label: 'Bank Transfer' },
            { value: 'credit_card', label: 'Credit Card' },
            { value: 'cash', label: 'Cash' }
          ],
          className: 'w-full'
        },
        {
          name: 'notes',
          label: 'Notes (Optional)',
          type: 'textarea',
          required: false,
          className: 'w-full'
        }
      ]
    }
  ];

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]" ref={ref}>
        <DialogHeader>
          <DialogTitle className="text-lg font-semibold">
            Process Payment
          </DialogTitle>
        </DialogHeader>
        <GenericForm
          ref={formRef}
          initialValues={initialValues}
          onSubmit={async (values) => {
            await onSubmit(values as PaymentFormValues);
            onOpenChange(false); // Close the modal after submission
          }}
          method="POST"
          layout="card"
          sections={sections}
        />
        <div className="flex justify-end gap-3 mt-6">
          <Button
            type="button"
            onClick={() => onOpenChange(false)}
            className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
          >
            Cancel
          </Button>
          <Button
              type="button"
              className="px-2 py-2 mx-1 bg-green-600 text-white rounded-md hover:bg-green-700 transition duration-300"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                } else {
                  console.error("Form ref is null");
                }
              }}
            >
              Submit
            </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
});

PaymentModal.displayName = 'PaymentModal';

export default PaymentModal;
